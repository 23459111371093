<template>
  <div >
     <!--篩選區塊-->
     <el-row>
       <el-button
          style="float: left;"
          size="small"
          type="primary"
          icon="el-icon-tickets"
          v-if="(list!=undefined&&list!=null&&list.length>0)&&(list==undefined||list==null||list.length<=0)"
          @click="exportReport()"
        >{{$t('ExportReport')}}
       </el-button>
 
       <div style="float: left;">
       <!--匯出CSV按鈕:若回傳listall若無資料則匯出按鈕不顯示-->
       <download-excel  type="csv"
                       v-if="listXls!=undefined&&listXls!=null&&listXls.length>0">
           <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="exportToCSV" >{{$t('DownloadAllDataCsv')}}</el-button>
       </download-excel>

       </div>
 
       <div style="float: left;">
       <!--listAll Export to XLS-->
       <excel-xlsx
         :data="listXls" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xls'" :sheet-name="'sheetname'"
         v-if="listXls!=undefined&&listXls!=null&&listXls.length>0"
         >
         <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="logOperate('report','/report/availability','EXPORT','匯出充電樁使用率統計報表 xls')">{{$t('DownloadAllDataXls')}}</el-button>
       </excel-xlsx>
       <!--listAll Export to XLS-->
       </div>
 
       <div style="float: left;">
       <!--listAll Export to XLSX-->
       <excel-xlsx
         :data="listXls" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xlsx'" :sheet-name="'sheetname'"
         v-if="listXls!=undefined&&listXls!=null&&listXls.length>0"
         >
         <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block" @click="logOperate('report','/report/availability','EXPORT','匯出充電樁使用率統計報表 xlsx')">{{$t('DownloadAllDataXlsx')}}</el-button>
       </excel-xlsx>
       <!--listAll Export to XLSX-->
       </div>
 
       <div style="float: right;">
                                                                <!--替換:充電站選擇-->
         <el-select v-model="listQuery.stationId" :placeholder="$t('ChargingStationS')+'('+$t('All')+')'"
            clearable size="small" class="filter-item-mid" width="140px">
           <el-option v-for="item in stationOption" :key="item.stationId"
                     :label="item.stationName" :value="item.stationId" />
         </el-select>
 
         <el-select v-model="listQuery.chargepointId" :placeholder="$t('ChargingPointS')+'('+$t('All')+')'"
            clearable size="small" class="filter-item-mid" width="140px">
           <el-option v-for="item in chargeOption"  :key="item.pointId"
                     :label="item.pointName" :value="item.pointId" />
         </el-select>
          <el-date-picker
             v-model="dateRange"
             size="small"
             class="filter-item"
             type="daterange"
             value-format="yyyy-MM-dd"
             range-separator="-"
             :start-placeholder="listQuery.startOn"
             :end-placeholder="listQuery.endOn">  <!--替換:開始日期、結束日期-->
           </el-date-picker>
      </div>
     </el-row>
     <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
            <span  v-if="chartDataExist" >產製時間: {{ reportDate }} </span>
        </el-row>
     <!-- table -->
 
     <div style="width:100%; height:280px; left:10px; display: flex;" >

<div  style="flex:1;">      
  <el-table
          size="mini"  
          :data="listAll" style="width: 100%;" :cell-style="{padding: '0', height: '20px'}"
          @sort-change="tableSortChange" :cell-class-name="tableColumnClass"        
     >     
       <!--<el-table-column align="center" prop="no" min-width="30px"        label="No." /> -->
       <el-table-column align="center" prop="no" label="編號" />
       <el-table-column align="center" prop="stationName"     min-width="60px"  :label="$t('ChargingStation')" /><!--替換:充電站-->
       <el-table-column align="center" prop="count" label="使用次數" />
       <el-table-column align="center" prop="usageRate" label="使用率" />
     </el-table>    
     <pagination v-show="total1>0" :total="total1" :current.sync="paginationSelectedPage1" :size.sync="listQuery1.size" @pagination="getList" />

</div>

<div style="flex:2;">      
<v-chart 
          :option="usageChart"
          :theme="chartsTheme"
          :update-options="{notMerge: true}"
          style="width: 100%; height:100%;"
      />   
</div>
</div>    

<div style= "margin: 50px 0;">
  
    <el-row> 
     <el-table
       size="mini"
       v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
       @sort-change="tableSortChange"
     >     
       <!--<el-table-column align="center" prop="no" min-width="30px"        label="No." /> -->
       <el-table-column align="center" prop="no" label="編號" />
       <el-table-column align="center" prop="stationName"     min-width="60px"  :label="$t('ChargingStation')" /><!--替換:充電站-->
       <!--el-table-column align="center" prop="pointName" min-width="60px"  :label="$t('ChargingPoint')" /><--替換:充電樁-->
       <el-table-column align="center" label="充電樁#槍">
        <template slot-scope="scope">
          {{ scope.row.pointName + "#" + scope.row.connectorId }}
        </template>
      </el-table-column>
       <!--el-table-column align="center" prop="connector_id"     min-width="50px"  :label="$t('ChargingConnector')" /--><!--替換:充電槍-->
       <el-table-column align="center" prop="count" label="使用次數" />
       <el-table-column align="center" prop="usageRate" label="使用率" />
       <!--el-table-column align="center" prop="account_value"              :label="$t('Account')"           sortable/><替換:用戶-->
       <!--el-table-column align="center" prop="start_time"                 :label="$t('Start')+$t('Time')"  sortable/><替換:充電開始時間-->
       <!--el-table-column align="center" prop="meter_start"                :label="$t('StartDegree')"       sortable/><替換:充電開始度數-->
       <!--el-table-column align="center" prop="stop_time"                  :label="$t('End')+$t('Time')"    sortable/><替換:充電結束時間-->
       <!--el-table-column align="center" prop="meter_stop"                 :label="$t('EndDegree')"         sortable/><替換:充電結束度數-->
       <!--el-table-column align="center" prop="ocpp_transaction_stop_reason" :label="$t('EndReasonId')"       sortable/><替換:充電結束原因-->
       <!--el-table-column align="center" prop="update_time" :label="$t('Update')+$t('Date')" sortable :show-overflow-tooltip="true" min-width="80px">
       <template slot-scope="scope"> {{ parseDateTime(scope.row.update_time) }}</template>
       </el-table-column-->

      <el-table-column align="center" fixed="right" label="明細" width="60px" >
          <template slot-scope="scope">
              <el-button v-if="scope.row.count!=0" type="success"  size="mini" icon="el-icon-view" @click="handleClick(scope.row)"></el-button> 
              <el-button v-if="scope.row.count==0" type="success"  size="mini" icon="el-icon-view" disabled @click="handleClick(scope.row)"></el-button>            
          </template>
      </el-table-column> 

     </el-table>
    
     <!--分頁組件-->
     <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
</el-row> 
  </div>
   </div>
 </template>
 
 <script>
 import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';
import moment from 'moment'; // 計算rangedate日數
 /* 每隔10min刷新出jwt token*/
 
 
 export default {
     name: 'Status',
     components: {
         Pagination
     },
     mixins: [mixins],
     data() {
         return {
             dialogWidth: 0,
             total: 0,
             total1: 0,
             stationOption    : undefined,
             chargeOption     : undefined,
             connectorOption  : undefined,
             chargePointOption:undefined,
             list: [],
             listAll: [],
             listXls: [],
             listCsv: [],
             listLoading: false,
             listQuery: {
                 //serviceId: this.$store.state.currentAccount.id,
                 serviceId: this.$store.state.currentAccount.serviceInfo.id,
                 stationId        : undefined,
                 chargepointId    : undefined,
                 //chargeConnectorId: undefined,
                 startOn:undefined, // this.$moment().subtract(30, "days").format("YYYY-MM-DD"),//30天前
                 endOn:  undefined,// this.$moment().format("YYYY-MM-DD"),
                 queryDays: 31,
                 page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                 size: 15,
                 sort: "update_time,asc",
             },
             listQuery1: {
                 //serviceId: this.$store.state.currentAccount.id,
                 serviceId: this.$store.state.currentAccount.serviceInfo.id,
                 stationId        : undefined,
                 chargepointId    : undefined,
                 //chargeConnectorId: undefined,
                 startOn:undefined, // this.$moment().subtract(30, "days").format("YYYY-MM-DD"),//30天前
                 endOn:  undefined,// this.$moment().format("YYYY-MM-DD"),
                 queryDays: 31,
                 page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                 size: 15,
                 sort: "update_time,asc",
             },
             size           : undefined,
             paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
             paginationSelectedPage1: 1, // 需配合Pagination元件與listQuery使用
             dateRange: undefined,
             queryDays: undefined,
             dialogSettingVisible: false,
             targetUrl:undefined,
             reportDate: undefined,
             json_fields: {
               "No"   : "no",
               "StationName"     : "stationName",
               "PointName"   : "pointName",
               "Count" : "count",
               "UsageRate"     : "usageRate",
             },
             xlsxColumns : [
                 {
                    // label: this.$t('ChargingConnector'),
                    label: '編號',
                     field: "no",
                 },
                 {
                     //label: this.$t('Account'),
                     label: '充電站',
                     field: "stationName",
                 },
                 {
                     //label: this.$t('Start')+this.$t('Time'),
                     label: '充電樁',
                     field: "pointName",
                 },
                 {
                     //label: this.$t('StartDegree'),
                     label: '使用次數',
                     field: "count",
                 },
                 {
                     //label: this.$t('End')+this.$t('Time'),
                     label: '使用率',
                     field: "usageRate",
                 },
                 ],
             /* 匯出檔名 */
             filenameCsv:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls",
             filenameAll:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls",
             filenameAllXlsx: this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll",
      
        chartDataExist : false, // 是否有圖表資料
            xAxisData      : [],    // x軸 日期時間
            yAxisData      : [],    // y軸  
            y2AxisData     : [],    // y軸2        
         };
     },
   created() {
     //定時刷新
     //refreshToken();
 
     this.dialogWidth = this.setDialogWidth()
 
     this.getStationByServiceId();
 
     
     if(this.$route.params.stationId != undefined) 
     {
      this.chargePointOption = this.$route.params.stationId;   
    }
     //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
     this.resetPage(this.getList);
   },
   mounted() {
     window.onresize = () => {
       return (() => {
         this.dialogWidth = this.setDialogWidth()
       })()
     }
   },
   beforeDestroy() {
     console.log("Call stopRefreshToken");
     //stopRefreshToken();
   },
   watch: {
     paginationSelectedPage: {
       handler: function(newValue) {
         this.listQuery.page = newValue - 1
       }
     },
     paginationSelectedPage1: {
       handler: function(newValue) {
         this.listQuery1.page = newValue - 1
       }
     },
     /* 偵測若語系切換,則進行本組件切出去在切回來,以達本組件語系包含子組件(含對話視窗)都刷新 */
     '$i18n.locale': function(val) {
       console.log('Role組件重載前')
       this.$router.push( { path:'/refresh',query:{ fromPath:this.$route.fullPath } })
       console.log('Role組件重載完成')
       },
     /* ----------------------------------------------------------------------------- */
     'listQuery.stationId': function (val) {
      this.listQuery.page = 0;
      this.listQuery1.page = 0;
      this.listQuery.chargepointId = undefined;
      this.listAll= [];
      this.listXls= [];
      this.listCsv= [];
      this.listSum= null;
      this.chartDataExist = false;
      this.chargeOption=null;
      /* 若充電站有被選擇,則才會找該充電站所屬的充電樁,沒被選擇則stationId為0 */
      if( this.listQuery.stationId != null && this.listQuery.stationId != undefined && this.listQuery.stationId > 0 ) {
        this.axios.get("/api/v1/carbon/pointidListByserviceIdandstationId?serviceId="+this.listQuery.serviceId+"&stationId="+val).then(
            res => {
           // console.log("Request:pointidListByserviceIdandstationId [res.data]",res.data);
            this.chargeOption=res.data;
            }
            );
      } else { 
        this.listQuery.stationId = undefined;
        this.listQuery1.stationId = undefined;
      } 
      this.listQuery1.stationId = val;
      //console.log("listQuery.stationId =", this.listQuery.stationId );
      //console.log("listQuery1.stationId =", this.listQuery1.stationId );
      this.resetPage(this.getList);
     },
    'listQuery.chargepointId': function (val) {
      this.listQuery.page = 0;
      this.listQuery1.page = 0;
      this.listQuery.connectorId = undefined;
      this.listAll= [];
      this.listXls= [];
      this.listCsv= [];
      this.listSum= null;
      this.chartDataExist = false;
      this.connectorOption=null;
      /* 若充電樁有被選擇,則才會找該充電樁所屬的充電槍,沒被選擇則chargepointId為"" */

      if( this.listQuery.chargepointId != null && this.listQuery.chargepointId != undefined && this.listQuery.chargepointId != "" ) {
        this.axios.get("/api/v1/carbon/connectoridListBy3Id?serviceId="+this.listQuery.serviceId+"&stationId="+this.listQuery.stationId+"&chargepointId="+val).then(
            res => {
            //console.log("Request:connectoridListBy3Id [res.data]",res.data);
            this.connectorOption=res.data;
            }
            );
      } else this.listQuery.chargepointId = undefined;
      this.resetPage(this.getList);

     },
    'listQuery.connectorId': function (val) {
      this.listQuery.page = 0;
      this.listQuery1.page = 0;
      this.listAll= [];
      this.listXls= [];
      this.listCsv= [];
      this.listSum= null;
      this.chartDataExist = false;
      //this.connectorOption=null;
      if( this.listQuery.connectorId != null && this.listQuery.connectorId != undefined && this.listQuery.connectorId != "" ) {
        this.resetPage(this.getList);      
      } else this.listQuery.connectorId = undefined;
     },
       /*
     'listQuery.chargeConnectorId': function (val) {
       this.listQuery.page = 0;
       this.resetPage(this.getList);
       this.listAll= [];
       //this.connectorOption=null;
      },*/
     dateRange: function(val) {
       if (val !=null ) {    
         this.listQuery.startOn = this.dateRange[0]
         this.listQuery.endOn = this.dateRange[1]
         this.listQuery1.startOn = this.dateRange[0]
         this.listQuery1.endOn = this.dateRange[1]
         const start = moment(this.dateRange[0]);
        const end = moment(this.dateRange[1]);
        const diff = end.diff(start, 'days');
        this.listQuery.queryDays = diff +1;
        this.listQuery1.queryDays = diff +1;
        //console.log("queryDays",this.queryDays);
       } else {
        this.listQuery.startOn = this.$moment().format("YYYY-MM-01");//.startOf('month').format('YYYY-MM-DD');
         this.listQuery.endOn   = this.$moment().format("YYYY-MM-DD");
         this.listQuery1.startOn = this.$moment().format("YYYY-MM-01");//.startOf('month').format('YYYY-MM-DD');
         this.listQuery1.endOn   = this.$moment().format("YYYY-MM-DD");
         const start = moment( this.listQuery.startOn);
        const end = moment(this.listQuery.endOn);
        const diff = end.diff(start, 'days');
        this.listQuery.queryDays = diff +1;
        this.listQuery1.queryDays = diff +1;
       }
       this.listQuery.page = 0;
       this.listQuery1.page = 0;  
      this.listAll= [];
      this.listXls= [];
      this.listCsv= [];
       this.resetPage(this.getList)
       //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
       
     },
   },
   computed: {
    usageChart() {
        let _this = this  
        let option = {     
          tooltip: {
            trigger: "axis",              
          }, 
          legend: {              //顯示該圖表資訊      
            data: ['各站使用次數'],  
            //bottom :1            //文字置底
          },        
          xAxis: {        
            type: 'category',  
            data: _this.xAxisData
          },
          yAxis: {
            type: 'value',
            name: '使用次數',
            nameTextStyle:{
              color:'Indigo', 
              //fontSize:12,  
            },
            data: _this.yAxisData        
          },                  
          series: [
            {
              data: _this.yAxisData, //圖表的數據
              type: 'bar',      //圖表類型        
              symbol: 'none',
              name:'使用次數',  
              color:'Indigo',
              stack: true,      
            },                         
          ]
        }
        return option
      }   
  },
   methods: {
    exportToCSV() {
      this.logOperate('report','/report/usage','EXPORT','匯出充電樁使用率統計報表 csv');
//const csvContent = "data:text/csv;charset=Big5," + this.convertToCSV(this.listXls); //utf-8 
const csvContent = this.convertToCSV(this.listCsv); //utf-8 
//const csvContent = new Blob('\ufeff${csv}', {type: 'text/plain;charset=utf-8'})+ this.convertToCSV(data);
//const encodedUri = encodeURI(csvContent);
const link = document.createElement("a");
//link.setAttribute("href", encodedUri);
//link.setAttribute("download", "data.csv");
//document.body.appendChild(link);
//link.click();

// "\ufeff"是为了解决CSV中文
let blob = new Blob(["\ufeff" +csvContent],{type: 'text/csv;charset=utf-8;'});
let url = URL.createObjectURL(blob);
link.href = url;
link.setAttribute('download', this.filenameAllXlsx);
link.click();
},
convertToCSV(data) {
const columnDelimiter = ",";
const lineDelimiter = "\n";
const keys = Object.keys(data[0]);

let result = "";
result += keys.join(columnDelimiter);
result += lineDelimiter;

data.forEach(item => {
  let row = "";
  keys.forEach(key => {
    row += item[key] + columnDelimiter;
  });
  result += row.slice(0, -1);
  result += lineDelimiter;
});

return result;
},
    openFullScreen(){
      this.loading = this.$loading(
        {
          lock: true,
          text: '圖表產生中',
          spinner: 'el-icon-loading',
          background: 'rgba(0,0,0,0.7)',
        }
      );
      this.timer = setTimeout(
        ()=>{this.getchart();},
        1500
      );  
    },
     getStationByServiceId() {
       //console.log("GET:/api/v1/report/stationidListByserviceId?serviceId="+this.listQuery.serviceId);
       this.axios.get("/api/v1/report/stationidListByserviceId?serviceId="+this.listQuery.serviceId).then(
           res => {
           //console.log("Response:/api/v1/report/stationidListByserviceId [res.data]",res.data);
           this.stationOption=res.data;
           }
          );
     },
     timeToSeconds(time) {
      //console.log("time", time);
      let parts= time.split('時');
      let hours =  parseFloat(parts[0]);
      //console.log("hours", hours);
      parts= parts[1].split('分');
      let minutes =  parseFloat(parts[0]);
      //console.log("minutes", minutes);
      parts= parts[1].split('秒');
      let seconds =  parseFloat(parts[0]);
      //console.log("seconds", seconds);
      //console.log("seconds",((hours * 3600) + (minutes * 60) + seconds));// /(this.queryDays * 24 * 60 *60 ));
      //console.log("days in seconds ",this.listQuery.queryDays );
  
 return (((hours * 3600 + minutes * 60 + seconds)/(this.listQuery.queryDays * 24 * 60 *60 ))*100).toFixed(2) + '%';

},
timeUsage(time,count) {
      //console.log("time", time);
      let parts= time.split('時');
      let hours =  parseFloat(parts[0]);
      console.log("hours", hours);
      parts= parts[1].split('分');
      let minutes =  parseFloat(parts[0]);
      console.log("minutes", minutes);
      parts= parts[1].split('秒');
      let seconds =  parseFloat(parts[0]);
      console.log("seconds", seconds);
      //console.log("seconds",((hours * 3600) + (minutes * 60) + seconds));// /(this.queryDays * 24 * 60 *60 ));
      //console.log("days in seconds ",this.listQuery.queryDays );
  
 return (((hours * 3600 + minutes * 60 + seconds)/(this.listQuery.queryDays * 24 * 60 *60 *count))*100).toFixed(2) + '%';

},
     getList() {
       let number = this.page
       let size   = this.size
       //console.log("this.dateRange[0]:",this.dateRange[0]);
       if( (this.listQuery.startOn==undefined) || (this.listQuery.endOn==undefined) ||  this.dateRange == null  ) {
         //this.listQuery.startOn = this.$moment().subtract(30, "days").format("YYYY-MM-DD")//90天前;
         this.listQuery.startOn = this.$moment().format("YYYY-MM-01");//.startOf('month').format('YYYY-MM-DD');
         this.listQuery.endOn   = this.$moment().format("YYYY-MM-DD")
         this.listQuery1.startOn = this.$moment().format("YYYY-MM-01");//.startOf('month').format('YYYY-MM-DD');
         this.listQuery1.endOn   = this.$moment().format("YYYY-MM-DD")
         const start = moment( this.listQuery.startOn);
        const end = moment(this.listQuery.endOn);
        const diff = end.diff(start, 'days');
        this.listQuery.queryDays = diff +1;
        this.listQuery1.queryDays = diff +1;
       }
       else
       {
        this.listQuery.startOn = this.dateRange[0];
        this.listQuery.endOn = this.dateRange[1];
        this.listQuery1.startOn = this.dateRange[0];
        this.listQuery1.endOn = this.dateRange[1];
        const start = moment( this.listQuery.startOn);
        const end = moment(this.listQuery.endOn);
        const diff = end.diff(start, 'days');
        this.listQuery.queryDays = diff +1;
        this.listQuery1.queryDays = diff +1;
       }
       this.listLoading = true

       //http://localhost:6783/api/v1/countusage/usageLog?serviceId=1&stationId=1&chargepointId=bf6f7aa1-f377-4522-9dcd-31535b285434&startOn=2024-05-01&endOn=2024-05-05
       //console.table('GET:/api/v1/report/status [params]:',this.listQuery);
      // console.log("1");
      this.targetUrl ="/api/v1/countusage/usageLog"; //?serviceId=" +this.listQuery.serviceId;
      /*
       if( this.listQuery.stationId != undefined && this.listQuery.stationId != null && this.listQuery.stationId != "" ) {
        this.targetUrl += "&stationId=" +  this.listQuery.stationId ;
       }
       //console.log("2");
       if( this.listQuery.chargepointId != undefined &&  this.listQuery.chargepointId != null && this.listQuery.chargepointId != "" ) {
        this.targetUrl += "&chargepointId=" +  this.listQuery.chargepointId ;
       }
      // console.log("3");
       this.targetUrl += "&startOn=" +this.listQuery.startOn + "&endOn=" + this.listQuery.endOn ; 
 
      console.log("this.targetUrl ",this.targetUrl ) 
      */
       //this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
        this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
         //console.log("Response:/api/v1/report/status [res.data]",res.data)
         this.list = res.data.content
         this.listXls = res.data.content
         this.total = res.data.totalElements
         //this.list = this.contentList
         number = res.data.number
         size   = res.data.size
        
         /* 讓 this.list的Map加入一個key為no */
         let output = this.list.map( ( item , index ) => {
             item.no = index + number*size +1             
             return item
         })

         this.list.forEach(usage => {
          usage.usageRate=this.timeToSeconds(usage.chargetime) + "("+usage.chargetime+")";      
          //usage.usageRate=(this.timeToSeconds(usage.chargetime) /(this.queryDays * 24 * 60 *60 )) + "("+usage.chargetime+")";
         })
         /* 讓 this.list的Map加入一個key為no */
        // console.log("list =",this.list)
         //
         //this.listAll = res.data.content
 // console.log("listAll =",this.listAll)

  //
         
       })
       //http://localhost:6783/api/v1/countusage/usagecountLog?serviceId=1&stationId=1&startOn=2024-05-01&endOn=2024-05-05
       this.targetUrl ="/api/v1/countusage/usagecountLog"//?serviceId=" +this.listQuery.serviceId+ "&startOn=" +this.listQuery.startOn + "&endOn=" + this.listQuery.endOn ;
      // console.log("targetUrl =",this.targetUrl);
    this.axios.get(this.targetUrl, {params: this.listQuery1}).then(res=> {
      this.total1 = res.data.totalElements
      number = res.data.number
         size   = res.data.size
  this.listAll = res.data.content
           /* 讓 this.list的Map加入一個key為no */
           let output1 = this.listAll.map( ( item , index ) => {
             item.no = index + number*size +1             
             return item
         })
  this.xAxisData= []
this.yAxisData= []
this.y2AxisData= [] 
   
  res.data.content.forEach(e =>
  {
    e.usageRate=this.timeUsage(e.chargetime,e.connectorNum) + "("+e.chargetime+")";      
    this.chartDataExist = true
    this.xAxisData.push(e.stationName )
    this.yAxisData.push(e.count )                    
    this.y2AxisData.push(e.usageRate )
    this.reportDate = this.formatCurrentDateTime()
  })
  this.exportReport()
  this.chartloading = false
  //console.log("listAll ",this.listAll)

  this.listLoading = false
  })
     },

     async getchart() {
      let number = this.page
       let size   = this.size
this.size          = this.listQuery.size
this.listQuery.page=0
this.listQuery.size=10000
this.chartDataExist = false
this.chartloading = true
this.xAxisData= []
this.yAxisData= []
this.y2AxisData= []      

//await/this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
  this.targetUrl ="/api/v1/countusage/usagecountLog"//?serviceId=" +this.listQuery.serviceId+ "&startOn=" +this.listQuery.startOn + "&endOn=" + this.listQuery.endOn ;
      // console.log("targetUrl =",this.targetUrl);
 await  this.axios.get(this.targetUrl, {params: this.listQuery1}).then(res=> {
  this.listAll = res.data.content
  this.total = res.data.totalElements
  console.log("listAll =",this.list)
  number = res.data.number
         size   = res.data.size
  res.data.content.forEach(e =>
  {
    this.chartDataExist = true
    this.xAxisData.push(e.stationName )
    this.yAxisData.push(e.count )                    
    this.y2AxisData.push(e.usageRate )
    let output = this.listAll.map( ( item , index ) => {
             item.no = index + number*size +1             
             return item
         })
  })
  this.chartloading = false
})

this.listQuery.size=this.size
this.filenameCsv =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls"
this.filenameAll =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls"
this.filenameAllXlsx= this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" 

},
     exportReport(){
       this.size          = this.listQuery.size
       this.listQuery.page=0
       this.listQuery.size=100000
       let number = this.page
       let size   = this.size
      // this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
        this.targetUrl ="/api/v1/countusage/usageLog"; 
        this.axios.get(this.targetUrl, {params: this.listQuery}).then(res => {
         //console.log("Response:/api/v1/report/status [res.data]",res.data)
         this.listXls = res.data.content
         this.listCsv = res.data.content
         number = res.data.number
   size   = res.data.size

   let output = this.listXls.map( ( item , index ) => {
       item.no = index + number*size +1             
       return item
   })         

                       //    console.log("this.listCsv",this.listCsv)
        //this.total = res.data.totalElements
         //this.list = this.contentList
         //number = res.data.number
         //size   = res.data.size
         this.listCsv =  this.listXls.map(( item , index ) => {
          item.no = index + number*size +1     
        return {
          編號: item.no,
          充電站: item.stationName,
          充電樁: item.pointName,
          使用次數: item.count,
          使用率: this.timeToSeconds(item.chargetime) + "("+item.chargetime+")"
        };
      });
       })
       this.listQuery.size=this.size
       this.filenameCsv =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReport" + ".xls"
       this.filenameAll =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll" + ".xls"
       this.filenameAllXlsx= this.$moment().format("YYYYMMDD-HHmmss").toString() + "_UsageReportAll"
      // this.listQuery.size=15
     },
     handleRowClick(row, column, event) {
     },
     handleClick(row){
       this.$router.push({
       name:'Pay.chargingrecord',
       params: { 
          start    : this.listQuery.startOn,
          end      : this.listQuery.endOn,
          serviceId: this.listQuery.serviceId, 
          stationId: row.stationID 
        }
      })
    },
     /*
     objectSpanMethod({ row, column, rowIndex, columnIndex}) {
       if (columnIndex ===0) {
         if(rowIndex %2 === 0) {
           return { rowspan:2, colspan:1};
         } else {
           return { rowspan:0, colspan:0};
         }
       }
     }, */
   }
 }
 
 </script>
 